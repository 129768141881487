import styled from "styled-components";
import {Box} from "@mui/material";

const StyledFooter = styled(Box)`
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.spacing(3)};
    
    .Footer {
        &-links {
            display: flex;
            gap: ${props => props.theme.spacing(1)};
            &-link {
                color: ${props => props.theme.palette.text.secondary};
            }
        }
    }
`;

export default StyledFooter;