import {Avatar, Box, Typography, Stack} from "@mui/material";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import StyledProfileHeader from "./ProfileHeader.styles";

type Props = {};

export default function ProfileHeader(props: Props) {

  return (
    <StyledProfileHeader>
      <Box className="ProfileHeader-headshot">
          <Avatar className="ProfileHeader-headshot-avatar"
                  alt="Isaac Donado" src={`${process.env.REACT_APP_FRONTEND_URL}/headshot.jpg`}/>
      </Box>
      <Typography className="ProfileHeader-name">Isaac Donado</Typography>
      <Typography className="ProfileHeader-title">Software Engineer</Typography>
      <Box className="ProfileHeader-info">
        <Stack direction="row" alignItems="center" gap={1}>
          <SchoolOutlinedIcon fontSize="small" />
          <Typography>CODAM - 42 network</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <WorkOutlineOutlinedIcon fontSize="small" />
          <Typography>Mobile Developer @ Elements</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <NearMeOutlinedIcon fontSize="small" />
          <Typography>
            Amsterdam, Netherlands
          </Typography>
        </Stack>
      </Box>
    </StyledProfileHeader>
  );
}
