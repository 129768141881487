import { Router } from "@remix-run/router";
import { createBrowserRouter } from "react-router-dom";
import ProfilePage from "../components/pages/ProfilePage/ProfilePage";
import MainTemplate from "../components/templates/MainTemplate/MainTemplate";

export const router: Router = createBrowserRouter([
  {
    path: "/",
    element: <MainTemplate />,
    errorElement: <div>Hello Error!</div>,
    children: [
      {
        id: "profile",
        path: "/",
        element: <ProfilePage />,
        errorElement: <div>Hello Error!</div>,
      },
    ],
  },
  //   {
  //     path: "/projects",
  //     element: (
  //       <MainTemplate
  //         title={"Projects"}
  //         description={"A list of projects I've completed or collaborated in..."}
  //         child={<ProjectsPage />}
  //       />
  //     ),
  //     errorElement: <div>Hello Error!</div>,
  //   },
  //   {
  //     path: "/notes",
  //     element: (
  //       <MainTemplate
  //         title={"Notes"}
  //         description={"Browse available notes and documentation..."}
  //         child={<div>Hello world</div>}
  //       />
  //     ),
  //     errorElement: <div>Hello Error!</div>,
  //   },
  {
    path: "/buggy",
    element: <div>Hello Error!</div>,
    errorElement: <div>Hello Error!</div>,
  },
]);
