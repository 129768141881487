import styled from "styled-components";
import {Dialog} from "@mui/material";

const StyledConsoleDialog = styled(Dialog)`
    .MuiPaper-root {
        width: clamp(300px, 40%, 960px);
        height: clamp(300px, 40%, 960px);
        background: #f6f6f6; /* Old browsers */
        background: linear-gradient(to bottom,  #f6f6f6 0%,#dadada 100%); /* W3C */
        margin: auto;
        overflow: hidden;
    }
    
    &.ConsoleDialog-maximize {
        .MuiPaper-root {
            max-width: unset;
            max-height: unset;
            height: 100%;
            width: 100%;
            margin: 0;
        }
    }
    
    .ConsoleDialog {
        &-top {
            position: absolute;
            width: 100%;
            display: flex;
            align-items: center;
            height: 22px;
            background-color: #28292B;
            padding: 0 ${props => props.theme.spacing(1)};
            &-buttons {
                display: flex;
                gap: 2px;
                .circle {
                    width: 12px;
                    height: 12px;
                    display: inline-block;
                    border-radius: 15px;
                    margin-left: 2px;
                    border-width: 1px;
                    border-style: solid;
                    &:hover {
                        cursor: pointer;
                    }
                }
                .red { 
                    background: #EC6A5F; border-color: #D04E42;
                }
                .green { background: #64CC57; border-color: #4EA73B; }
                .yellow{ background: #F5C04F; border-color: #D6A13D; }
            }
        }
        &-window {
            position: absolute;
            overflow: auto;
            top: 22px;
            width: 100%;
            height: calc(100% - 22px);
            padding: 10px;
            color: white;
            background-color: black;
            font-family: "JetBrains Mono", monospace;
            
            &-input {
                .MuiInput-root {
                    width: 50px;
                    font-family: "JetBrains Mono", monospace;
                    caret-color: transparent;
                    &::before, &::after {
                        border: none;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: -15px;
                        /* Remove display: inline-block if not required to be on the same line as text etc */
                        display: inline-block;
                        background-color: #606060;
                        vertical-align: top;
                        width: 10px;
                        /* Set height to the line height of .text */
                        height: 24px;
                        /* 
                        Animation paramaters:
                        blink = animation-name, 
                        1s = animation-duration, 
                        step-end = animation-timing-function,
                        infinite = animation-iteration-count
                        */
                        -webkit-animation: blink 1s step-end infinite;
                        animation: blink 1s step-end infinite;
                    }

                    @-webkit-keyframes blink {
                        0% { opacity: 1.0; }
                        50% { opacity: 0.0; }
                        100% { opacity: 1.0; }
                    }

                    @keyframes blink {
                        0% { opacity: 1.0; }
                        50% { opacity: 0.0; }
                        100% { opacity: 1.0; }
                    }
                }
            }
        }
    }
`;

export default StyledConsoleDialog;
