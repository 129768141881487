import styled from 'styled-components';
import {Box} from "@mui/material";

const StyledMainTemplate = styled(Box)`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
  .MainTemplate {
      &-content {
          flex-grow: 1;
      }
      &-footer {
      }
  }
`;

export default StyledMainTemplate;
