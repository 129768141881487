import styled from 'styled-components';
import {Box} from "@mui/material";

const StyledProfilePage = styled(Box)`
  .ProfilePage {
      &-container {
          display: flex;
          flex-direction: column;
          margin-top: ${props => props.theme.spacing(8)};
          margin-bottom: ${props => props.theme.spacing(2)};
          gap: ${props => props.theme.spacing(2)};
      }
      &-description {
          .MuiLink-root {
              text-decoration: none;
          }
      }
      &-bottom {
          display: flex;
          gap: ${props => props.theme.spacing(1)};
          &-button {
              min-width: 150px;
          }
      }
  }
`;

export default StyledProfilePage;
