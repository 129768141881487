import {createGlobalStyle} from "styled-components";

const GlobalStyles = createGlobalStyle`
  body {
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: #40b6c3;
  }

  a:visited {
    color: #40b6c3;
  }
`;

export default GlobalStyles;
