import { ThemeOptions } from "@mui/material";
import Montserrat from "../styles/fonts/Montserrat-VariableFont_wght.ttf";

export const getTheme = (darkmode: boolean): ThemeOptions => {
  return {
    palette: {
      mode: darkmode ? "dark" : "light",
      primary: { main: "#40b6c3", contrastText: "#f1f1f1" },
      secondary: {
          main: '#a3a3a3',
          light: '#e9e9e9',
          dark: '#ededed',
          contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: 'Montserrat, "Helvetica Neue", Arial, sans-serif',
      fontSize: 14,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Montserrat';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            src: local('Montserrat'), local('Montserrat-Regular'), url(${Montserrat}) format('truetype');
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }
        `,
      },
    },
  };
};
