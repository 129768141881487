import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface ProfileState {
  headshot: string | null;
  name: string | null;
  title: string | null;
  education: string | null;
  work: string | null;
  location: string | null;
}

// Define the initial state using that type
const initialState: ProfileState = {
  headshot: null,
  name: null,
  title: null,
  education: null,
  work: null,
  location: null,
};

export const profileSlice = createSlice({
  name: "profile",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleDarkmode: (state: ProfileState) => {
      state.name = null;
    },
  },
});

export const { toggleDarkmode } = profileSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectProfileHeaderInfo = (state: RootState) => {
  return {
    headshot: state.profile.headshot,
    name: state.profile.name,
    title: state.profile.title,
    education: state.profile.education,
    work: state.profile.work,
    location: state.profile.location,
  };
};

export default profileSlice.reducer;
