import styled from 'styled-components';
import {Box} from "@mui/material";

const StyledProfileHeader = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ${props => props.theme.spacing(1)};
    padding: ${props => props.theme.spacing(3)};
    background-color: ${props => props.theme.palette.action.hover};
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    
  .ProfileHeader {
      &-headshot {
          &-avatar {
              box-shadow: 0 10px 25px 0 rgba(0,0,0,.3);
              width: 100px;
              height: 100px;
          }
      }
      &-name {
          font-size: 1.75rem;
          font-weight: 500;
          line-height: 1.2;
      }
      &-title {
          font-size: 1.25rem;
          font-weight: 500;
          line-height: 1.5;
          --bs-text-opacity: 0.7;
      }
      &-description {
          line-height: 1.5;
          --bs-text-opacity: 0.7;
      }
      &-info {
          max-width: 400px;
          padding-left: 5px;
          padding-right: 5px;
      }
  }
`;

export default StyledProfileHeader;
