import * as React from "react";
import {
  Box,
  Typography,
  Container,
  Skeleton, Button, Link,
} from "@mui/material";
import { useQuery } from "react-query";
import StyledProfilePage from "./ProfilePage.styles";
import {Email, Terminal} from "@mui/icons-material";
import ConsoleDialog from "../../organisms/Dialogs/ConsoleDialog/ConsoleDialog";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {selectConsole, toggleConsole} from "../../../store/slices/appSlice";
import {AppDispatch} from "../../../store/store";

export default function ProfilePage() {
  const console: boolean = useAppSelector(selectConsole);
  const dispatch: AppDispatch = useAppDispatch();

  const handleConsoleClick = () => {
    dispatch(toggleConsole());
  };

  const handleConsoleClose = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    dispatch(toggleConsole())
  };


  return (
    <StyledProfilePage>
      <ConsoleDialog open={console} onClose={handleConsoleClose}/>
      <Container component="main" className="ProfilePage-container" maxWidth="sm">
        <Typography variant="h4" component="h1" gutterBottom>
          Hello world
        </Typography>
        <Typography className="ProfilePage-description" gutterBottom>
          I'm a motivated software developer based in Amsterdam. I've been into tech my whole life and was raised by the internet. I formally began my coding journey at <Link href="https://www.codam.nl/" target="_blank" >CODAM Coding College</Link>  [<Link href="https://www.42network.org/" target="_blank" >42 Network</Link>] where I completed the core curriculum which helped me build and polish skills such as Imperative Programming, Network/System Administration, Graphics, Unix & AI. Currently, I'm a mobile developer at <Link href="https://www.elements.nl/" target="_blank" >Elements</Link> and on a mission to complete the Codam Advanced curriculum in Web & Mobile Development.
        </Typography>

        <Box className="ProfilePage-bottom" sx={{display: 'flex', justifyContent: 'center'}}>
          <Button className="ProfilePage-bottom-button" variant="outlined" size="large" color="secondary" onClick={handleConsoleClick} fullWidth={false} startIcon={<Terminal />}>
            <Typography>More</Typography>
          </Button>
          <Link className="Footer-links-link" href="mailto:isaac@donado.io" target="_blank" >
            <Button className="ProfilePage-bottom-button" variant="outlined" size="large" color="secondary" fullWidth={false} startIcon={<Email />}>
              <Typography>Contact</Typography>
            </Button>
          </Link>
        </Box>

      </Container>

    </StyledProfilePage>
  );
}
