import {QueryClient, QueryClientProvider} from "react-query";

const queryClient: QueryClient = new QueryClient();

type Props = {
    children: any;
};

export default function QueryProvider(props: Props) {
    const { children } = props;
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
}