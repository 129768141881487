import {Box, Typography, Link} from "@mui/material";
import StyledFooter from "./Footer.styles";
import {GitHub, LinkedIn, Mail} from "@mui/icons-material";

export const Footer: React.FC<any> = () => {
  return <StyledFooter>
      <Box className="Footer-copyright">
          <Typography variant="body2" color="text.secondary">
              {"Copyright © "}
              {new Date().getFullYear()}
          </Typography>
      </Box>
      <Box className="Footer-links">
          <Link className="Footer-links-link" href="mailto:isaac@donado.io" target="_blank" >
              <Mail/>
          </Link>
          <Link className="Footer-links-link" href="http://www.github.com/donadoio" target="_blank" >
              <GitHub/>
          </Link>
          <Link className="Footer-links-link" href="http://www.linkedin.com/in/isaacdonado" target="_blank" >
              <LinkedIn/>
          </Link>
      </Box>
  </StyledFooter>
};
