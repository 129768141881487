import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getTheme } from "./utils/getTheme";
import GlobalStyles from "./styles/GlobalStyles";
import { RouterProvider } from "react-router-dom";
import { router } from "./navigation/AppRouter";
import QueryProvider from "./components/organisms/Providers/QueryProvider/QueryProvider";
import { useAppSelector } from "./store/hooks";
import { selectDarkmode } from "./store/slices/appSlice";
import {useEffect} from "react";

function App() {
  const darkmode: boolean = useAppSelector(selectDarkmode);
  const theme = createTheme(getTheme(darkmode));

    useEffect(() => {
        document.title = 'Isaac Donado - Software Engineer';
    }, []);
  return (
    <QueryProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <RouterProvider router={router} />
      </ThemeProvider>
    </QueryProvider>
  );
}

export default App;
