import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { Footer } from "../../Footer/Footer";
import AppNavBar from "../../organisms/Navbars/AppNavBar";
import PageHeader from "../../organisms/Headers/PageHeader";
import { Location, Outlet, useLocation } from "react-router-dom";
import ProfileHeader from "../../organisms/Headers/ProfileHeader/ProfileHeader";
import StyledMainTemplate from "./MainTemplate.styles";

type Props = {};

export default function MainTemplate(props: Props) {
  const location: Location = useLocation();
  const isProfile = (location.pathname = "/");
  return (
    <StyledMainTemplate className="MainTemplate">
        <CssBaseline />
        <AppNavBar />
        {isProfile ? <ProfileHeader /> : <PageHeader />}
        <Box className="MainTemplate-content" >
            <Outlet/>
        </Box>
        <Footer className="MainTemplate-footer" />
    </StyledMainTemplate>
  );
}
