import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";

// Define a type for the slice state
interface AppState {
  name: string;
  darkmode: boolean;
  console: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
  name: "donado.io",
  darkmode: true,
  console: false,
};

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleDarkmode: (state: AppState) => {
      state.darkmode = !state.darkmode;
    },
    toggleConsole: (state: AppState) => {
      state.console = !state.console;
    },
  },
});

export const { toggleDarkmode, toggleConsole } = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectConsole = (state: RootState) => state.app.console;
export const selectDarkmode = (state: RootState) => state.app.darkmode;
export const selectAppName = (state: RootState) => state.app.name;

export default appSlice.reducer;
