import {Box, Typography} from "@mui/material";

type Props = {};

export default function PageHeader(props: Props) {
  return (
    <Box
      component="main"
      sx={{
        pt: 13,
        pb: 5,
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[800],
        borderBottom: 1,
        borderColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[300]
            : theme.palette.grey[700],
      }}
    >
      <Typography>Test Title</Typography>
      <Typography>short description</Typography>
    </Box>
  );
}
