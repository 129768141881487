import {Box, TextField} from "@mui/material";
import StyledConsoleDialog from "./ConsoleDialog.styles";
import {PropsWithChildren, useEffect, useRef, useState} from "react";

type Props = {
    open: boolean,
    onClose: (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => void
};

export default function ConsoleDialog(props: PropsWithChildren<Props>) {
    const inputRef = useRef<any>(null);
    const [menu, setMenu] = useState<number>(0);
    const [maximize, setMaximize] = useState<boolean>(false);

    const handleMaximize = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        setMaximize(!maximize);
    };

    const handleMinimize = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        props.onClose(e);
    };

    const doClose = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        setMenu(0);
        setMaximize(false);
        props.onClose(e);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        inputRef.current?.focus();
        if (e.key === 'Escape') {
            doClose(e);
        } else if (menu === 0) {
            if (e.key === '1') {
                setMenu(1);
            } else if (e.key === '2') {
                setMenu(2);
            } else if (e.key === '3') {
                doClose(e);
            }
        } else if (menu === 1) {
            setMenu(0);
        } else if (menu === 2) {
            if (e.key === '3') {
                setMenu(0);
            } else if (e.key === '1') {
                window.open(
                    'http://linkedin.com/in/isaacdonado',
                    '_blank'
                );
            } else if (e.key === '2') {
                window.open(
                    'http://github.com/donadoio',
                    '_blank'
                );
            }
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (inputRef.current) {
            inputRef.current?.focus();
        }
    };

    const renderTextField = () => <TextField inputProps={{ref: inputRef}} className="ConsoleDialog-window-input" variant="standard" value=""/>;
    const renderMainMenu = () => {
        return <>
            % [ Main menu ]<br/>
            % 1. Skills<br/>
            % 2. Links<br/>
            % 3. Exit<br/>
            % <br/>
            % Type a number: {renderTextField()}
        </>
    };

    const renderSkills = () => {
        return <>
            % [ Skills ]<br/>
            % Frontend:<br/>
            % React, React Native, Swing<br/><br/>
            % Backend: <br/>
            % NestJS, Express.js, Django, Spring Boot (50%)<br/><br/>
            % Programming languages: <br/>
            % Javascript, Java, C, C++, Kotlin<br/><br/>
            % Other tools: <br/>
            % Docker, Typescript, PostgreSQL<br/><br/>
            % Press any key to go back... {renderTextField()}
        </>
    };

    const renderLinks = () => {
        return <>
            % [ Links ]<br/>
            % 1. LinkedIn<br/>
            % 2. Github<br/>
            % 3. Go back<br/>
            % <br/>
            % Type a number: {renderTextField()}
        </>
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, [menu])

    return <StyledConsoleDialog className={`ConsoleDialog${maximize ? '-maximize' : ''}`} open={props.open} onKeyUp={handleKeyPress} onClick={handleClick}>
        <Box className="ConsoleDialog-top">
            <Box className="ConsoleDialog-top-buttons">
                <Box className="circle red" onClick={doClose}></Box>
                <Box className="circle yellow" onClick={handleMinimize}></Box>
                <Box className="circle green" onClick={handleMaximize}></Box>
            </Box>
        </Box>
        <Box className="ConsoleDialog-window">
            {menu === 0 ? renderMainMenu() : null}
            {menu === 1 ? renderSkills() : null}
            {menu === 2 ? renderLinks() : null}
        </Box>
    </StyledConsoleDialog>
}
