import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectConsole,
  selectDarkmode, toggleConsole,
  toggleDarkmode,
} from "../../../store/slices/appSlice";
import { AppDispatch } from "../../../store/store";
import {Code, Key, Lightbulb} from "@mui/icons-material";
import ConsoleDialog from "../Dialogs/ConsoleDialog/ConsoleDialog";


export default function AppNavBar() {
  const darkmode: boolean = useAppSelector(selectDarkmode);
  const console: boolean = useAppSelector(selectConsole);
  const dispatch: AppDispatch = useAppDispatch();


  const DarkmodeButton = () => {
    return (
        <IconButton
            size="large"
            aria-label="darkmode"
            aria-controls="menu-appbar"
            aria-haspopup="false"
            onClick={() => dispatch(toggleDarkmode())}
        >
          {darkmode ? <Lightbulb /> : <DarkModeIcon />}
        </IconButton>
    );
  };

  const handleConsoleClose = (e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
    dispatch(toggleConsole())
  };

  return (
    <>
      <ConsoleDialog open={console} onClose={handleConsoleClose}/>

        <AppBar position="static" color="transparent">
          <Toolbar>
            <Box sx={{flexGrow: 1}}><Code sx={{marginRight: 1}} /></Box>
            <DarkmodeButton />
          </Toolbar>
        </AppBar>

    </>
  );
}
